<template>
  <v-row align="center" class="list px-3 mx-auto">
    <div class="submit-form mt-3 mx-auto">
      <div class="submit-form">
        <div class="form-group">
          <label for="className">Название класса</label>
          <input
            type="text"
            class="form-control"
            id="className"
            required
            v-model="sclass.className"
            name="className"
          />
        </div>
        <div class="form-group">
          <label for="classLider">Классный руководитель</label>
          <input
            type="text"
            class="form-control"
            id="classLider"
            required
            v-model="sclass.classLider"
            name="classLider"
          />
        </div>
        <div class="form-group">
          <label for="classLider">Пароль</label>
          <input
            type="text"
            class="form-control"
            id="classLider"
            required
            v-model="sclass.password"
            name="classLider"
          />
        </div>
        <br />
      </div>
      <div>
        Смена
        <select class="form-select" v-model="sclass.shiftSchool">
          <option
            v-for="cat in sShift"
            :key="cat.shiftSchool"
            v-bind:value="cat.shiftSchool"
          >
            {{ cat.shiftSchool }}
          </option>
        </select>
        Уровень образования:
        <select class="form-select" v-model="sclass.levelEdu">
          <option value="1">Начальное</option>
          <option value="2">Основное</option>
          <option value="3">Среднее</option>
        </select>
      </div>
      <br />
      <div style="display: flex; justify-content: center; padding: 0 0 10px 0">
        <button
          @click="create"
          class="btn btn-success"
          style="width: 500px; padding: 10px"
        >
          Создать класс
        </button>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 0 10px 0;
        "
      >
        <button
          @click="importClass()"
          class="btn btn-dark"
          style="width: 500px; margin-right: 5px"
        >
          Импорт классов
        </button>
        <button
          @click="importStudents()"
          class="btn btn-dark"
          style="width: 500px; margin-left: 5px"
        >
          Импорт учеников
        </button>
      </div>
      <div style="display: flex; justify-content: center; padding: 0 0 10px 0">
        <button
          type="button"
          class="btn btn-primary"
          @click="toDataStudents()"
          aria-label="Close modal"
          style="color: white"
        >
          Данные учеников
        </button>
      </div>
    </div>
    <br />
    <div>
      <v-col>
        <v-card>
          <v-card-title>Список классов</v-card-title>

          <v-data-table
            :headers="headers"
            :items="sclass"
            :items-per-page="100"
            hide-default-footer
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="select-flex">
                <select
                  class="form-select"
                  name=""
                  id=""
                  v-model="item.levelEdu"
                >
                  <option value="1">Начальное</option>
                  <option value="2">Основное</option>
                  <option value="3">Среднее</option>
                </select>
                <div class="select">
                  <button
                    class="btn btn-success"
                    style="margin-right: 10px"
                    @click="updateLevelEdu(item)"
                  >
                    Обновить Уровень образования
                  </button>
                </div>
                <div class="select" style="padding-right: 20px">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="item.passwordChange"
                    name="classLider"
                    v-bind:id="item.id"
                    style="padding-right: 20px; width: 150px"
                  />
                </div>

                <div class="select">
                  <button class="btn btn-success" @click="updatePassword(item)">
                    Обновить пароль
                  </button>
                </div>

                <v-card-actions>
                  <button class="btn btn-danger" @click="deleteClass(item.id)">
                    Удалить
                  </button>
                </v-card-actions>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </div>

    <modal v-show="showModalCheck" @close="importStudents()" />
    <importClass v-show="showImportClass" @close="importClass()" />
  </v-row>
</template>
<style>
@import "../assets/style.css";
</style>
<script>
import modal from "./importStudents.vue";
import importClass from "./importClass.vue";
import TutorialDataService from "../services/TutorialDataService";
import ServerCommandLogin from "../services/ServerCommandLogin";

export default {
  components: { modal, importClass },

  name: "tutorials-list",
  data() {
    return {
      sShift: [{ shiftSchool: "1 смена" }, { shiftSchool: "2 смена" }],
      sclass: [],
      title: "",
      headers: [
        { text: "Класс", align: "start", sortable: true, value: "className" },
        { text: "Классный руководитель", value: "classLider", sortable: false },
        { text: "Смена", value: "shiftSchool", sortable: false },
        { text: "Уровень", value: "level", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showModalCheck: false,
      showImportClass: false,
    };
  },
  methods: {
    toDataStudents() {
      this.$router.push("/DataStudents");
    },
    importStudents() {
      this.showModalCheck = !this.showModalCheck;
    },
    importClass() {
      this.showImportClass = !this.showImportClass;
    },

    updateLevelEdu(data) {
      let sendData = {
        id: data.id,
        level: data.levelEdu,
        className: data.className,
      };
      TutorialDataService.updateLevelEduClass(data.id, sendData)
        .then((response) => {
          console.log(response.data);
          alert("Уровень образования изменен!");
          for (let i = 0; i < this.sclass.length; i++) {
            if (sendData.id === this.sclass[i].id) {
              this.sclass[i].level = sendData.level;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updatePassword(data) {
      console.log(data);
      let sendData = {
        id: data.id,
        passwordChange: data.passwordChange,
        className: data.className,
      };
      console.log(sendData);

      ServerCommandLogin.updatePassword(data.id, sendData)
        .then((response) => {
          console.log(response.data);
          alert("Пароль успешно изменен");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    create() {
      var data = {
        className: this.sclass.className,
        classLider: this.sclass.classLider,
        shiftSchool: this.sclass.shiftSchool,
        password: this.sclass.password,
        level: this.sclass.levelEdu,
      };

      TutorialDataService.createClass(data)
        .then((response) => {
          this.sclass.id = response.data.id;

          console.log("УСПЕШНО ОТПРАВЛЕНО", response.data.id);
          this.refreshList();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    deleteClass(id) {
      console.log("ID============", id);
      TutorialDataService.delete(id)
        .then(() => {
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveTutorials() {
      TutorialDataService.getAllCLass()
        .then((response) => {
          this.sclass = response.data.map(this.getDisplayTutorial);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveTutorials();
    },

    getDisplayTutorial(data) {
      return {
        id: data._id,
        className: data.className,
        classLider: data.classLider,
        shiftSchool: data.shift,
        level: data.level,
        levelEdu: "",
      };
    },
  },
  mounted() {
    this.retrieveTutorials();
  },
};
</script>

<style>
.submit-form {
  max-width: 300px;
}
</style>
